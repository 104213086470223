import React, { useEffect, useState } from 'react';

import creatoki from '../../../../assets/creatoki.png';
import { ButtonVariantsEnum } from '../../../../utils/_enums/button-variants.enum';
import { Button, ButtonProps } from '../../button/button';
import styles from './stage-image-button-token.module.scss';
import { LinkBase } from '../../link/link-base/link-base';

export interface Props extends Omit<ButtonProps, 'icon' | 'variant'> {
    image: string;
    shadow?: boolean;
    editButtonProps?: Omit<ButtonProps, 'children'>;
    hasLink?: boolean;
    href?: string;
}
export const StageImageButtonToken: React.FC<Props> = ({
                                                           image,
                                                           editButtonProps,
                                                           shadow,
                                                           ...props
                                                       }) => {
    const [src, setSrc] = useState('');

    useEffect(() => {
        setSrc(`${image}?resolution=thumbnail`);
    }, [image]);

    return (
        <div
            className={styles['stage-image-button-token']}
        >
            {
                props?.hasLink ?
                    <LinkBase className={styles['stage-image-button-token-link']} href={props.href as string}>
                        <Button
                            {...props}
                            variant={
                                shadow
                                    ? ButtonVariantsEnum.STAGE
                                    : ButtonVariantsEnum.SECONDARY_FILLED
                            }
                            className={styles['token-button']}
                            icon={{
                                element: <img
                                    src={src}
                                    onError={() => setSrc(creatoki.src)}
                                />, align: 'left'
                            }}
                            buttonUrl={''}
                        />
                    </LinkBase>
                    : <Button
                        {...props}
                        variant={
                            shadow
                                ? ButtonVariantsEnum.STAGE
                                : ButtonVariantsEnum.SECONDARY_FILLED
                        }
                        className={styles['token-button']}
                        icon={{
                            element: <img
                                src={src}
                                onError={() => setSrc(creatoki.src)}
                            />, align: 'left'
                        }}
                        buttonUrl={''}
                    />
            }
            {editButtonProps && (
                <Button {...editButtonProps} className={styles.editButton} />
            )}
        </div>
    );
};
