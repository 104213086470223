import Image from 'next/image';
import React from 'react';

import DefaultHeader from '/src/assets/default_header.jpg';

import useWindowSize from '../../../utils/_hooks/useWindowSize.hook';
import styles from './stage-image-background.module.scss';

interface StageImageBackgroundProps {
    url?: string;
    className?: string;
    alt?: string;
    objectPosition?: NonNullable<JSX.IntrinsicElements['img']['style']>['objectPosition'];
}

const StageImageBackground: React.FC<StageImageBackgroundProps> = (props: StageImageBackgroundProps): JSX.Element => {

    const [width] = useWindowSize();

    return width
        ? <Image className={`${styles['stage-background']} ${props.className ? props.className : ''}`}
                 src={props.url ?? DefaultHeader.src}
                 alt={props.alt}
                 layout={'fill'}
                 sizes={width > 1920 ? '100vw' : '1920px'}
                 objectPosition={props.objectPosition}
                 objectFit={'cover'}
                 priority
        />
        : <div/>
};

export default StageImageBackground;

StageImageBackground.defaultProps = {
    objectPosition: 'top'
}