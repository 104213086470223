import React from 'react';

export const LogoText: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            viewBox="0 0 712.086 98.201"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <defs />
            <path
                className="a"
                d="M513.391 1.999h13.812v41.566a44.654 44.654 0 008.289.5c16.45 0 27.627-10.422 27.627-28 0-4.394-.127-9.794-.378-14.063h13.814c.252 4.521.376 9.418.376 13.562 0 18.835-8.79 32.148-24.236 37.923l28.507 42.7h-16.829l-26.12-39.807c-1.381.125-2.761.125-4.144.125a63.517 63.517 0 01-6.906-.376v40.058h-13.814zM189.311 2.009h54v12.054h-40.186V42.57h37.673V54.5h-37.673v29.511h40.81v12.181h-54.627zM288.428 2.009h17.831l31.4 94.184h-14.818l-7.409-23.735h-36.544l-7.534 23.735h-14.315zm-5.652 58.268h28.758l-14.065-44.706h-.5zM361.373 14.063h-25.115V2.009h64.044v12.054h-25.115v82.129h-13.814zM601.85 2.009h13.812v94.184H601.85zM67.645 80.668a37.288 37.288 0 01-18.161 4.565c-9.528 0-17.958-3.6-25.055-10.694a35.6 35.6 0 010-50.877c7.1-7.1 15.527-10.694 25.055-10.694a33.723 33.723 0 0117.09 4.478c3.406 1.938 8.786 6.4 11.471 8.77-.032-.064 9.315-9.182 9.315-9.182l-.934-1.051C76.507 5.378 64.336 0 50.25 0 36.219 0 24.211 4.859 14.557 14.443S-.001 35.599-.001 48.811c0 13.19 4.819 24.834 14.319 34.608 9.531 9.808 21.1 14.782 34.4 14.782h1.727c14.234 0 26.353-5.392 36.021-16.026l.986-1.085s-8.955-8.7-8.923-8.739a47.12 47.12 0 01-10.884 8.317zM662.861 2.009h17.831l31.393 94.184h-14.818l-7.409-23.735h-36.543l-7.536 23.735h-14.315zm-5.652 58.268h28.756L671.9 15.571h-.5zM490.436 23.882l-10.412 10.3a42.831 42.831 0 012.53 14.8c0 21.474-13.814 36.669-33.781 36.669s-33.78-15.194-33.78-36.669c0-21.223 13.814-36.669 33.78-36.669a32.973 32.973 0 0119.445 6l9.305-9.239A48.029 48.029 0 00448.772.011c-27.754 0-47.972 20.719-47.972 49.1s19.842 49.1 47.972 49.1c27.752 0 47.846-20.719 47.846-49.1a52.243 52.243 0 00-6.182-25.229zM147.01 57.513c13.689-3.14 22.73-13.812 22.73-27.376 0-15.947-12.934-28.255-31.019-28.255h-34.784v94.183h13.814V13.939h19.591c11.426 0 18.334 7.282 18.334 16.326 0 8.79-6.908 16.45-18.334 16.45h-12.1l6.828 11.678 22.728 37.673h15.949z"
            />
        </svg>
    );
};
