import Link from 'next/link';
import React, {ReactNode} from 'react';

import CreatokenSVGComponent from '../../../assets/icons/creatoken.svg';
import {Alignment} from '../../../utils/_enums/alignment.enum';
import {StrapiHeadlineElements} from '../../../utils/_enums/strapi-headline-elements.enum';
import {StrapiHeadlineStyles} from '../../../utils/_enums/strapi-headline-styles.enum';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import {mapStrapiHeadline} from '../../../utils/_helper/strapi-mapper';
import {Button, ButtonProps} from '../button/button';
import {LinkBase} from '../link/link-base/link-base';
import StageImageBackground from '../stage-image-background/stage-image-background';
import {Typography} from '../typography/typography';
import styles from './stage.module.scss';
import {
    Props as StageImageButtonTokenProps,
    StageImageButtonToken
} from './stage-image-button-token/stage-image-button-token';

export interface Props {
    logo?: ReactNode;
    orientation?: 'LEFT' | 'RIGHT' | 'CENTER';
    verticalOrientation?: 'TOP' | 'CENTER' | 'BOTTOM';
    size?: 'Large' | 'Small';
    title: string;
    titleVariant?: StrapiHeadlineStyles;
    titleElement?: StrapiHeadlineElements;
    description: string;
    inverted?: boolean;
    buttons?: ButtonProps[];
    background?: string;
    textDirection?: Alignment;
    decButton?: ButtonProps | StageImageButtonTokenProps;
    className?: string;
}

export const Stage: React.FC<Props> = ({
                                           title,
                                           titleVariant,
                                           verticalOrientation,
                                           titleElement,
                                           description,
                                           orientation,
                                           inverted,
                                           buttons,
                                           background,
                                           logo,
                                           decButton,
                                           textDirection,
                                           size,
                                           className = ''
                                       }) => {
    return (
        <div
            data-testid="stage"
            className={`${styles['stage']} ${
                styles[size as string]
            } ${className}`}
        >
            <StageImageBackground url={background}/>
            <div>
                <div
                    className={`${styles[orientation as string]} ${
                        inverted ? styles['inverted'] : ''
                    } ${styles[`vert-${verticalOrientation?.toLowerCase()}`]}`}
                >
                    {logo}
                    <div
                        className={
                            textDirection === Alignment.HORIZONTAL
                                ? styles['horizontal-text-alignment']
                                : ''
                        }
                    >
                        {title && (
                            <div className={styles['title']}>
                                <Typography
                                    className={
                                        textDirection === Alignment.HORIZONTAL
                                            ? styles['horizontal-text']
                                            : ''
                                    }
                                    variant={
                                        titleVariant
                                            ? mapStrapiHeadline(titleVariant)
                                            : TypographyVariantsEnum.HEADING1
                                    }
                                    element={titleElement ? titleElement : 'h1'}
                                >
                                    {title}
                                </Typography>
                            </div>
                        )}
                        <div
                            className={
                                textDirection === Alignment.HORIZONTAL
                                    ? styles['flex-container']
                                    : ''
                            }
                        />
                        {description && (
                            <div className={styles['description']}>
                                <Typography
                                    variant={TypographyVariantsEnum.INTROTEXT}
                                >
                                    {description}
                                </Typography>
                            </div>
                        )}
                    </div>
                    {buttons && buttons.length > 0 && (
                        <div className={styles['buttons']}>
                            {buttons?.map((props) => {
                                if (props?.buttonUrl && props?.external)
                                    return (
                                        <LinkBase
                                            key={props.children as string}
                                            href={props?.buttonUrl ?? ''}
                                            external={props?.external}
                                        >
                                            <Button
                                                inverted={inverted}
                                                {...props}
                                            />
                                        </LinkBase>
                                    );
                                else if (props?.buttonUrl)
                                    return (
                                        <Link
                                            key={props.children as string}
                                            href={props?.buttonUrl ?? ''}
                                            passHref
                                        >
                                            <a>
                                                <Button
                                                    inverted={inverted}
                                                    {...props}
                                                />
                                            </a>
                                        </Link>
                                    );
                                else
                                    return (
                                        <Button
                                            inverted={inverted}
                                            {...props}
                                        />
                                    );
                            })}
                        </div>
                    )}
                </div>
                <div>
                    {(decButton as StageImageButtonTokenProps)?.image ? (
                        <div className={styles.decButtonCustomContainer}>
                            <StageImageButtonToken
                                {...(decButton as StageImageButtonTokenProps)}
                                shadow
                            />
                        </div>
                    ) : (
                        <div className={styles.decButtonContainer}>
                            {decButton?.icon?.element}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Stage.defaultProps = {
    orientation: 'CENTER',
    inverted: false,
    size: 'Large',
    decButton: {
        icon: {
            element: <CreatokenSVGComponent/>,
            align: 'right'
        }
    },
    verticalOrientation: 'CENTER'
};
